import { createRouter, createWebHistory } from "vue-router";
import PlanetS from "../views/PlanetS.vue";
import Error from "../views/Error.vue";

const routes = [
  //Home page is currently also the Planet S page
  { path: "/", name: "home", component: PlanetS },
  //Planet S page
  { path: "/planet_s", name: "planet_s", component: PlanetS },
  //{ path: "/planet_s", name: "planet_s", component: () => import(/* webpackChunkName: "planet-s" */ "../views/PlanetS.vue") },
  //Everything else -> 404 Not found
  { path: "/:pathMatch(.*)*", component: Error },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
