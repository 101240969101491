<template>
    <a v-bind:href="destination" class="frame_button">
        <img class="frame_button_icon" alt="Missing image" v-bind:src="require('../assets/images/social_media/' + icon + '.svg')"/>
    </a>
</template>

<style lang="scss">
    @import "/public/css/planet_s/styles.scss";

    .frame_button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4em;
        height: 4em;
        margin: 0.5em;
        @include button();
        &:hover {
            @include buttonHover();
        }

        .frame_button_icon {
            width: 3em;
            height: 3em;
        }
    }
</style>

<script>
    export default {
        name: "PlanetSButton",
        props: ["icon", "destination"],
    }
</script>