<template>
    <div class="body">
        <section class="centerSection">
            <main class="main">
                <p>
                    The requested site does not exist.
                </p>
            </main>
        </section>
    </div>
</template>

<style lang="scss">
    //@import "/public/css/colors.scss";

    .main {
        background-color: red;
    }
</style>

<script>
    export default {
        name: "Error",
        components: {},
    }
</script>