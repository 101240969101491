<template>
	<div class="body">
		<router-view></router-view>
	</div>
</template>

<style lang="scss">
    html, body, #app, .body {
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
</style>