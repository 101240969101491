<template>
    <div class="background">
        <div class="frame">
            <img class="frame_logo" src="~@/assets/images/planet_s/Logo.png" />
            <iframe src="https://store.steampowered.com/widget/1948430/" frameborder="0" width="646" height="190"/>
            <div class="frame_links">
                <PlanetSButton icon="twitter" destination="https://twitter.com/TeamJADev"></PlanetSButton>
                <PlanetSButton icon="youtube" destination="https://www.youtube.com/@teamja5382"></PlanetSButton>
                <PlanetSButton icon="discord" destination="https://discord.gg/JhA5M5EvXS"></PlanetSButton>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "/public/css/planet_s/styles.scss";

    .background {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("~@/assets/images/planet_s/planetsBackground.jpg");
        height: 100%;
        margin: 0px;
        padding: 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 6.25em black;

        .frame {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 0.5em;
            width: 50em;
            height: 50em;
            @include glassPane();

            .frame_logo {
                width: 80%;
                height: auto;
                margin-bottom: 3em;
            }

            .frame_links {
                margin-top: 3em;
                width: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: row;
            }
        }
    }

    #app {
        background: black;
        height: 100%;
    }
</style>

<script>
    import PlanetSButton from "../components/PlanetSButton.vue";

    export default {
        name: "PlanetS",
        components: {
            PlanetSButton,
        },
    }
</script>